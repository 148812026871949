.loader {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1000;
  animation: color-change .5s linear forwards;
  animation-delay: .5s;

  &:before,
  &:after {
    z-index: 1001;
    content: '';
    border: 5px solid #333;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    animation: progress-pulsate 1s linear infinite;
    opacity: 0;
  }

  &:before {
    animation-delay: 1s;
  }

  &:after {
    animation-delay: .5s;
  }
}

@keyframes color-change {
  0% {
    background: transparent;
  }

  100% {
    background: rgba(255, 255, 255, 0.8);
  }
}

@keyframes progress-pulsate {
  0% {
    transform: translate(-50px, -50px) scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(-50px, -50px) scale(1);
    opacity: 0;
  }
}
