.tabs {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  font-size: var(--font-size-x-small);
}

.tab__link {
  display: flex;
  align-items: center;
  text-align: center;
  height: var(--tabs-height);
  text-decoration: none;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  margin-right: 2.5em;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  color: var(--color-text-link);

  &:visited {
    color: var(--color-text-link);
  }
}

.tabs li:last-child .tab__link {
  margin-right: 0;
}

.tab__link:hover,
.tab__link--active {
  border-bottom: 4px solid var(--primary-color-high-contrast);
}

.tab__link--active {
  font-weight: 700;
}
