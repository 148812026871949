.sticky-menu {
  box-shadow: 0 1px 9px 0 rgba(0,0,0,.2);
  width: 100%;
  background-color: var(--color-ui-01);
  z-index: 1;

  & .container {
    padding: 0;
  }
}

.sticky-menu--sticky-top {
  position: absolute;
  top: 0;
}

.sticky-menu--sticky-bottom {
  position: absolute;
  bottom: 0;
}

.sticky-menu__inner {
  width: var(--container-width);
  padding-left: calc(var(--container-padding) + var(--col-padding));
  padding-right: calc(var(--container-padding) + var(--col-padding));
  margin-left: auto;
  margin-right: auto;
}

.sticky-menu--sticky-fixed-top {

  /* Fixed to the viewport. */
  position: fixed;
  top: 0;
}

.sticky-menu__wrapper {

  /* Relative to make stickymenu's position absolute work as expected. */
  position: relative;
}

.sticky-menu__section {
  padding-top: calc(var(--tabs-height) + var(--default-padding));
}

.sticky-menu__current {
  display: none;
  font-weight: 600;
  text-transform: uppercase;
  user-select: none;
}

@media only screen and (max-width: 1023px) {
  .sticky-menu__current {
    display: block;
    padding-top: var(--default-padding);
    padding-bottom: var(--default-padding);
    cursor: pointer;
    padding-right: 40px;
    background: url('../../../assets/icons/arrow_down.svg');
    background: svg-load('../../../assets/icons/arrow_down.svg'),
      linear-gradient(transparent, transparent);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 30px;
    font-size: var(--font-size-x-small);
    height: var(--tabs-height);
    width: 100%;
  }

  .sticky-menu__inner {
    position: relative;
  }

  .sticky-menu {

    /* Transform the tabs into a dropdown */
    & .tabs {
      display: block;
      position: absolute;
      background-color: #fff;
      box-shadow: 0 4px 4px -4px #999;
      left: 0;
      right: 0;
      border: 1px solid #eee;
      border-bottom: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform .1s;

      &.tabs--visible {
        transform: scaleY(1);
      }

      & .tab__link {
        margin-right: 0;
        height: auto;
        padding: 1.5em 2em;
        border-bottom: 1px solid #eee;
        border-top: 0;
      }
    }
  }
}
