.analytics-card {
  width: 100%;
  max-width: 24.75rem; /* 396px */
  text-align: center;
  box-shadow: var(--card-shadow);
  padding: 1.875rem;
}

.analytics-card span {
  display: block;
  margin-bottom: 1.25rem;
}

/* Give all but the first card a margin-left (gutter) and a margin-top */
.analytics-card__coll > *:not(:first-of-type) {
  margin-left: var(--col-gutter);
  margin-top: var(--col-padding);
}

@media only screen and (max-width: 1023px) {
  .analytics-card__coll {
    text-align: center;
  }

  .analytics-card {
    display: inline-block;
  }

  /* Remove gutter on mobiles and tablets */
  .analytics-card__coll > *:not(:first-of-type) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .analytics-card__coll {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  /* Reset margin-top in desktop resolutions */
  .analytics-card__coll > *:not(:first-of-type) {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1359px) {
  .analytics-card {
    max-width: 18.5rem; /* 296px */
  }
}

@media only screen and (min-width: 1360px) {
  .analytics-card {
    max-width: 24.75rem; /* 396px */
  }
}
