.support-card {
  width: auto;
  max-width: 32rem;
  text-align: center;
}

.support-card img {
  margin: 0;
  max-width: 7rem;
  max-height: 7rem;
}

.support-card h3 {
  margin: 0.5rem 0 var(--default-padding) 0;
}

.support-card span {
  display: block;
  padding-bottom: 2rem;
}

.support-card a {
  margin: 0;
}

@media screen and (min-width: 1360px) {
  .support-card img {
    max-width: 8.75rem;
    max-height: 8.75rem;
  }

  .support-card span {
    padding-bottom: 2.75rem;
  }
}

.support-card__coll {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1024px) {
  .support-card__coll {
    flex-direction: row;
    justify-content: space-evenly;

    & .support-card {

      /* Equals 5 columns. */
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .support-card__coll {
    & .support-card {
      padding-bottom: 3.5rem;
    }
  }
}
