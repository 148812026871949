.tech-card {
  width: 100%;
  max-width: 25rem; /* 400px */
  text-align: center;
  padding: 1.75rem 0;
}

.tech-card img {
  margin: 0;
  max-height: 5rem; /* 80px */
  max-width: 5rem;
}

.tech-card h5 {
  padding: 0.5625rem 0 var(--default-padding) 0;
}

.tech-card span {
  display: block;
}

.tech-card a {
  display: inline-block;
  margin-top: var(--default-padding);
}

@media only screen and (max-width: 1023px) {
  .tech-card__coll {
    & .tech-card {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .tech-card {
    padding: 2rem 0;
  }

  .tech-card img {
    max-height: 5.625rem; /* 90px */
    max-width: 5.625rem;
  }
}

@media only screen and (min-width: 1024px) {
  .tech-card {
    padding: 2.625rem 3.5625rem 4.875rem 3.5625rem;

    /* Set explicit width instead of flex-basis due to IE box model bug */
    flex-basis: auto;
    width: 33.33333%;
  }

  .tech-card img {
    max-height: 6.25rem; /* 100px */
    max-width: 6.25rem;
  }

  .tech-card a {
    margin-top: 1.5rem;
  }

  .tech-card__coll {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
