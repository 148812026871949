.show-more__outer--active {
  overflow: hidden;
  transition: max-height var(--animation-speed) ease-out;
  position: relative;
}

.show-more__outer--active::after {

  /* This is the faded bottom gradient, to show that more content is available */
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  max-height: 150px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,.8));
  transition: max-height var(--animation-speed);
}

.show-more__outer.show-more--open::after {
  max-height: 0;
}

.show-more__toggle-outer {
  box-shadow: 0 -6px 4px -4px rgba(0,0,0,.3);
  position: relative;
  transition: all var(--animation-speed) ease-out;
  text-align: center;
}

.show-more__toggle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--font-size-x-small);
  font-weight: 700;
  letter-spacing: 0.075rem;
  color: var(--color-gray-05, #666666);
  transition: all var(--animation-speed) ease-out;
}

.show-more__toggle:hover,
.show-more__toggle:visited {
  color: var(--color-gray-05, #666666);
}

.show-more--open + .show-more__toggle-outer {
  box-shadow: 0 -8px 10px -11px rgba(0,0,0,0);
  transform: scaleY(0);
  max-height: 0 !important;
}

.show-more--open + .show-more__toggle-outer .show-more__toggle {
  opacity: 0;
}

.show-more__expand {
  margin-left: 10px;
  font-size: 1.5em;
}
