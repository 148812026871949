.modal__btn:hover,
.modal__btn:active {
  outline: 0 none;
}

.modal {
  background-color: rgba(0,0,0,0.8);
  visibility: hidden;
  height: 100%;
  left: 0;
  overflow: auto;
  opacity: 0;
  position: fixed;
  transition: opacity .15s linear, visibility 0.15s linear;
  top: 0;
  width: 100%;
  z-index: 1;
}

.show {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--animation-speed-fast) linear, visibility var(--animation-speed-fast) linear;
}

.modal__dialog {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.modal__header{
  display: flex;
  padding: 20px;
}

.modal__content {
  position: relative;
  background-color: #fefefe;
  margin: 5%;
  min-height: 250px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  width: var(--container-width);
  border-radius: 4px;
}

@media screen and (min-width: 768px) {
  .modal__content {
    margin: 5% auto;
  }
}

.modal__close {
  position: absolute;
  background: svg-load('../../assets/icons/close.svg');
  background-repeat: no-repeat;
  display: block;
  right: 0;
  height: 20px;
  margin-right: 20px;
  width: 20px;
}

.modal__close:hover,
.modal__close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

