.message {
  padding: var(--col-padding);
  border-left: 4px solid;
  border-radius: 4px;
}

.message--status {
  background-color: var(--color-status-secondary);
  border-left-color: var(--color-status-primary);
  color: var(--color-status-text);
}

.message--warning {
  background-color: var(--color-warning-secondary);
  border-left-color: var(--color-warning-primary);
  color: var(--color-warning-text);
}

.message--danger {
  background-color: var(--color-danger-secondary);
  border-left-color: var(--color-danger-primary);
  color: var(--color-danger-text);
}
