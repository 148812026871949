.select {
  color: var(--color-ui-02);
  padding: .71428em 4.5em .71428em 2em; /* 10px 63px 10px 28px */
  border: 2px solid var(--color-brand-02);
  border-radius: 4px;
  background: transparent
    url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjM5Ni42LDE2MCA0MTYsMTgwLjcgMjU2LDM1MiA5NiwxODAuNyAxMTUuMywxNjAgMjU2LDMxMC41ICIvPjwvc3ZnPg==')
    no-repeat
    right 1.5em top 50%;
  background-size: 30px;
  cursor: pointer;
  min-width: 120px;
  font-size: var(--font-size-x-small);
  line-height: var(--line-height-x-small);
  letter-spacing: var(--letter-spacing-x-small);
  font-weight: 700;
  appearance: none;
  transition: border 0.075s ease-out;

  &:hover {
    border: 2px solid var(--color-brand-03);
  }

  &:focus {
    outline: var(--outline);
  }

  &:disabled {
    opacity: .5;
    cursor: default;

    &:hover {
      border: 2px solid var(--color-brand-02);
    }
  }
}

.select::-ms-expand {
  display: none;
}
