.tagline {
  font-size: 1.1875rem; /* 19px */
  line-height: 1.57894; /* 30px */
  letter-spacing: 0.025rem; /* 0.4px */
}

@media only screen and (min-width: 1024px) {
  .tagline {
    font-size: 1.5625rem; /* 25px */
    line-height: 1.16; /* 29px */
    letter-spacing: 0.05rem; /* 0.8px */
  }
}
