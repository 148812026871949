/* The values using the em unit are based on a font-size of 20px */

.list {
  margin-left: 2em;
  list-style-type: none;
}

.list__item {
  line-height: 1.4em;
  margin-bottom: 0.6667em;
  position: relative;
}

.list__item:before {
  content: "";
  height: 0.6667em;
  width: 0.6667em;
  background-color: var(--primary-color-high-contrast);
  border-radius: 0.13333em;
  position: absolute;
  left: -2em;
  top: 0.4em;
  margin-right: 1.3333em;
}

/* Nested lists. */
.list .list {
  margin-top: 0.6667em;
  font-size: 1em;
}
