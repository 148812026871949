.carousel {
  display: flex;
  flex-direction: column;
  max-width: 900px;
}

.carousel__thumbs {
  list-style-type: none;
  margin: 2rem 0;
  display: inline-flex;
  flex-wrap: nowrap;

  & li:last-child .carousel__thumb {
    margin-right: 0;
  }
}

.carousel__thumbs-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
  width: 90%;
  margin: auto;
}

.carousel__thumb {
  margin-right: 2.5rem;
  display: block;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid transparent;
  float: left; /* ie fallback */

  & a {
    display: block;
  }

  & img {
    display: block;
    width: 56px;
  }
}

.carousel__thumb--active {
  border-bottom: 3px solid var(--primary-color-high-contrast);
}

.carousel__main {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  min-height: 1px; /* fixes ie 10 height bug */

  & a {
    display: block;
  }

  & img {
    max-width: 90%;
  }
}

.carousel__main-item {
  display: none;
  text-align: center;
}

.carousel__main-item--active {
  display: block;
}

@media screen and (min-width: 768px) {
  .carousel__main {
    overflow: hidden; /* IE fix to make item shrinkable */

    & img {
      max-width: 65%;
    }
  }
}
