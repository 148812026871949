.table {
  border-collapse: collapse;
  width: 100%;
}

.table__cell {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 0.75em 1.5em;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
    text-align: center;
  }
}

.table__row:last-child {
  & td {
    border-bottom: none;
  }
}

.table__caption {
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  border-top: 1px solid #666;
  padding: 0.75em 0;
}

.table__checkmark {
  @supports (background:url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' ?%3E%3Csvg height='32' id='check' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z'/%3E%3C/svg%3E")) {

    /* Only show svg icon in browsers that support it */
    background: svg-load('../../assets/icons/check.svg');
    height: 32px;
    width: 32px;
    display: block;
    margin: auto;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
}
