.button-cta {
  display: inline-block;
  border: 2px solid var(--primary-action-color);
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--font-size-button);
  line-height: var(--line-height-button-large);
  letter-spacing: 1px;
  color: var(--color-text-01);
  background-color: var(--primary-action-color);
  min-width: 8.125rem;
  padding: 1.4375rem 1.75rem; /* 23px 28px */

  &:visited,
  &:focus {
    color: var(--color-text-01);
  }
}

.button-cta--disabled {
  pointer-events: none;
  opacity: .5;
}

.button-cta--inverted {
  background-color: #fff;
  border-color: #fff;
}

@media screen and (max-width: 767px) {
  .button-cta {
    width: 100%;
  }
}
