.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  user-select: none;
}

/* Hide original checkbox */
.checkbox input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

/* Custom checkbox */
.checkbox__box {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--color-ui-02);
  border-radius: 3px;
  left: 0;
  bottom: 0.2rem;
}

.checkbox__label {
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: 600;
  margin-left: 0.7rem;
}

/* Checkmark */
.checkbox__box:after {
  position: absolute;
  display: none;
  content: '';
  top: 3px;
  left: 6px;
  width: 0.3rem;
  height: 0.5rem;
  transform: rotate(45deg);
  border: solid var(--color-text-01);
  border-width: 0 2px 2px 0;
}

/* Show checkmark */
.checkbox input:checked ~ .checkbox__box:after {
  display: block;
}

/* Focus state */
.checkbox input:focus ~ .checkbox__box {
  outline: 1px dotted var(--color-text-link-focus);
}

/* Disabled state */
.checkbox--disabled,
.checkbox input:disabled ~ .checkbox__box,
.checkbox input:disabled ~ .checkbox__box:after {
  color: #000;
  opacity: .4;
}

.checkbox input:disabled ~ .checkbox__box {
  pointer-events: none;
  background: var(--color-gray-01);
}

@media only screen and (min-width: 1360px) {
  .checkbox__box {
    bottom: 0.4rem;
  }
}
