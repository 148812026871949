.rel-link-card {
  display: inline-block;
  width: 100%;
  max-width: 26rem;
  text-align: center;
}

.rel-link-card img {
  margin: 0;
}

.rel-link-card h4 {
  padding: var(--default-padding) 0;
}

.rel-link-card span {
  display: block;
  padding-bottom: var(--default-padding);
}

.rel-link-card a {
  margin: var(--default-padding) 0 0 0;
}

.rel-link__coll {
  text-align: center;
}

/* Set mobile/tablet margin-bottom */
.rel-link__coll > *:not(:last-of-type) {
  margin-bottom: var(--col-gutter);
}

/* Set desktop margin-left */
.rel-link__coll > *:not(:first-of-type) {
  margin-left: var(--col-gutter);
}

@media only screen and (max-width: 1023px) {

  /* Reset gutter in mobile/tablet resolutions */
  .rel-link__coll > *:not(:first-of-type) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .rel-link__coll {
    display: flex;
    flex-direction: row;
  }

  /* Reset vertical spacing in desktop resolutions */
  .rel-link__coll > *:not(:last-of-type) {
    margin-bottom: 0;
  }
}
