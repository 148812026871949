/* The utilities layer, helpers, overrides etc. */

.clearfix {
  clear: both;
}

.img-responsive {
  max-width: 100%;
}

.text-small {
  @extend %text-body-s;
}

.text-xsmall {
  @extend %text-body-xs;
}
