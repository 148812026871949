:root {

  /* By color */
  --color-yellow-01: #fffae9;
  --color-yellow-02: #fff0c5;
  --color-yellow-03: #ffdb6e;
  --color-yellow-04: #ffcc33; /* --color-brand-01 */
  --color-yellow-05: #f5c430;
  --color-yellow-06: #e7ac00;
  --color-yellow-07: #bc8d00;
  --color-yellow-08: #8d6900;

  --color-warm-gray-01: #faf8f6;

  --color-warm-gray-03: #e6e3e0;
  --color-warm-gray-04: #d8cfc6; /* --color-brand-02 */

  --color-warm-gray-06: #9a8b7d; /* --color-brand-03 */

  --color-red-01: #fff5f7; /* --color-danger-secondary */

  --color-red-04: #ff0033; /* --color-brand-05 */

  --color-red-06: #b5121b; /* --color-brand-04 */
  --color-red-07: #670014;

  --color-blue-01: #eff9ff;

  --color-blue-03: #72cdf4; /* --color-brand-09 */
  --color-blue-04: #009ddc; /* --color-brand-08 */

  --color-blue-07: #004966;

  --color-gray-01: #e6e5e5;
  --color-gray-02: #cccccc;

  --color-gray-04: #8c8c8c;
  --color-gray-05: #666666;
  --color-gray-06: #555555;
  --color-gray-07: #444444;
  --color-gray-08: #333333;

  /* The custom properties are valid from mobile and up, unless overridden in media queries */
  --primary-action-color: #fc3;
  --primary-color-high-contrast: #f5c430;
  --primary-action-color-dark: #f0b400;
  --dark-text-color: #010;
  --background-secondary-light: #f4f1ee;
  --background-secondary-light-darken: #d4d1dd;

  /* Primary colors */
  --color-brand-01: #fc3;
  --color-brand-02: #d8cfc6;
  --color-brand-03: #9a8b7d;

  /* Secondary colors */
  --color-brand-04: #b5121b;
  --color-brand-05: #f03;
  --color-brand-08: #009ddc;
  --color-brand-09: #72cdf4;
  --color-brand-10: #8dc63f;

  /* UI colors */
  --color-ui-01: #fff;
  --color-ui-02: #333;

  /* Text colors */
  --color-text-01: rgba(0, 0, 0, 0.8);
  --color-text-link: #333;

  /* Link state colors */
  --color-text-link-hover: #333;
  --color-text-link-visited: #838383;
  --color-text-link-active: #838383;
  --color-text-link-focus: #333;

  /* Semantics */
  --color-status-primary: var(--color-blue-04);
  --color-status-secondary: var(--color-blue-01);
  --color-status-text: var(--color-blue-07);
  --color-warning-primary: var(--color-red-04);
  --color-warning-secondary: var(--color-red-01);
  --color-warning-text: var(--color-red-07);
  --color-danger-primary: var(--color-yellow-04);
  --color-danger-secondary: var(--color-yellow-01);
  --color-danger-text: var(--color-yellow-08);

  /* Widths & paddings */
  --default-padding: 15px;
  --col-padding: 0.75rem; /* 12px; */
  --col-gutter: 1.5rem; /* 24px, col-gutter is twice the size of col-padding, defined to avoid use of calc() function */
  --container-padding: 0.75rem; /* 12px */
  --container-width: 100%;

  /* Font settings */
  --font-size-default: 1rem; /* 16px */
  --letter-spacing-default: 0.0125rem; /* 0.2px */
  --line-height-default: 1.75; /* 28px */

  --font-size-small: 0.9375rem; /* 15px */
  --letter-spacing-small: 0.01125rem; /* 0.18px */
  --line-height-small: 1.4; /* 21px */

  --font-size-x-small: 0.8125rem; /* 13px */
  --letter-spacing-x-small: 0.0093rem; /* 0.15px */
  --line-height-x-small: 1.53846; /* 20px */

  --font-size-button: 0.875rem; /* 14px */
  --letter-spacing-button: 0.0125rem; /* 0.2px */
  --line-height-button: 1.1875; /* 19px */

  --font-size-button-large: 0.875rem; /* 14px */
  --letter-spacing-button-large: 0.0125rem; /* 0.2px */
  --line-height-button-large: 1.71428; /* 24px */

  /* Other */
  --tabs-height: 52px;
  --outline: 1px dotted #333;
  --animation-speed: .3s;
  --animation-speed-slow: .5s;
  --animation-speed-fast: .15s;
  --card-shadow: 0 0 12px rgba(0, 0, 0, .2);
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

  /* Small mode only */
  :root {
    --col-padding: 1.25rem; /* 20px; */
    --col-gutter: 2.5rem; /* 40px */
    --container-padding: 0.25rem; /* 4px */
    --container-width: 768px;
    --tabs-height: 52px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1359px) {

  /* Medium mode only */
  :root {
    --col-padding: 1.375rem; /* 22px; */
    --col-gutter: 2.75rem; /* 44px */
    --container-padding: 0.125rem; /* 2px */
    --container-width: 1024px;

    /* Font settings */
    --font-size-default: 1.0625rem; /* 17px */
    --letter-spacing-default: 0.0125rem; /* 0.2px */
    --line-height-default: 1.76470; /* 30px */
  }
}

@media only screen and (min-width: 1024px) {

  /* Medium mode and up */
  :root {

    /* Font settings */
    --font-size-small: 1.125rem; /* 18px */
    --letter-spacing-small: 0.0125rem; /* 0.2px */
    --line-height-small: 1.6666; /* 30px */

    --font-size-x-small: 0.875rem; /* 14px */
    --letter-spacing-x-small: 0.0093rem; /* 0.15px */
    --line-height-x-small: 1.7142; /* 24px */

    --font-size-button: 0.875rem; /* 14px */
    --letter-spacing-button: 0.075rem; /* 1.2px */

    --font-size-button-large: 1.125rem; /* 18px */
    --letter-spacing-button-large: 0.05rem; /* 0.8px */
    --line-height-button-large: 1.66666; /* 30px */

    --tabs-height: 70px;
  }
}

@media only screen and (min-width: 1360px) {

  /* Large mode and up */
  :root {
    --col-padding: 1.75rem; /* 28px */
    --col-gutter: 3.5rem; /* 56px */
    --container-padding: 0.125rem; /* 2px */
    --container-width: 1360px;

    /* Font settings */
    --font-size-default: 1.125rem; /* 18px */
    --letter-spacing-default: 0.01875rem; /* 0.3px */
    --line-height-default: 1.77777; /* 32px */
  }
}
