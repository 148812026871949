.search-bar {
  display: flex;
  width: 100%;
  max-width: 900px;
  align-items: stretch;
  background-color: var(--background-secondary-light);
  border-radius: 2rem;
}

.search-bar__input {
  border: 0;
  padding: 1rem 0 1rem 1rem;
  flex: 1;
  font-size: var(--font-size-default);
  color: var(--color-text-01);
  position: relative;
  text-overflow: ellipsis;
  background-color: transparent;
  width: 100%;

  /* The following line needed for Firefox to make flex-shrink work as expected */
  min-width: 0;

  &:focus {
    outline: 1px dotted gray;
    outline-offset: -1px;
  }
}

.search-bar__submit {
  cursor: pointer;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: 0;
  position: relative;

  /* png as fallback, override with svg when supported */
  background: url('./assets/icons/search-24px.png');
  background: svg-load('../../../assets/icons/search-24px.svg'),
    linear-gradient(transparent, transparent);
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: 200%;
  overflow: hidden;
  white-space: nowrap;
  width: 3.75rem;
  flex-shrink: 0;

  &:focus {
    outline: 1px dotted gray;
    outline-offset: -1px;
  }

  &::-moz-focus-inner {

    /* Remove the double focus outline/border. */
    border: 0;
  }
}

.search-bar--light {
  background-color: #fff;
}

.search-bar__input--light {
  color: #898888;
  font-size: 1.125rem; /* 18px */
}
