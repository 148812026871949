@font-face {
  font-family: 'Lubalin Graph ECG';
  src: url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Book.eot');
  src: url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Book.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Book.woff') format('woff'),
    url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lubalin Graph ECG';
  src: url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Demi.eot');
  src: url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Demi.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Demi.woff') format('woff'),
    url('./assets/fonts/lubalin-graph-ecg/LubalinGraphECG-Demi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/open-sans/OpenSans-Light-webfont.eot');
  src: url('./assets/fonts/open-sans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/open-sans/OpenSans-Light-webfont.woff') format('woff'),
    url('./assets/fonts/open-sans/OpenSans-Light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/open-sans/OpenSans-Regular-webfont.eot');
  src: url('./assets/fonts/open-sans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/open-sans/OpenSans-Regular-webfont.woff') format('woff'),
    url('./assets/fonts/open-sans/OpenSans-Regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/open-sans/OpenSans-Semibold-webfont.eot');
  src: url('./assets/fonts/open-sans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/open-sans/OpenSans-Semibold-webfont.woff') format('woff'),
    url('./assets/fonts/open-sans/OpenSans-Semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/open-sans/OpenSans-Bold-webfont.eot');
  src: url('./assets/fonts/open-sans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/open-sans/OpenSans-Bold-webfont.woff') format('woff'),
    url('./assets/fonts/open-sans/OpenSans-Bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}
