.link {
  font-weight: 700;
  text-decoration: none;
  border-bottom: 1px solid var(--color-text-link);
  color: var(--color-text-link);

  &:hover {
    color: var(--color-text-link-hover);
  }

  &:visited {
    color: var(--color-text-link-visited);
  }

  &:active {
    color: var(--color-text-link-active);
  }

  &:focus {
    outline: 1px dotted var(--color-text-link-focus);
    color: var(--color-text-link-focus);
  }
}
