.accessory-card {
  display: grid;
  grid-template-columns: auto 1fr;
  box-shadow: var(--card-shadow);
  padding: 1.25rem;
  overflow: hidden;
}

.accessory-card__media {
  grid-column: 1 / 2;
  max-height: 5.625rem; /* 90px */
  max-width: 5.625rem;
  align-self: center;
}

.accessory-card img {
  min-width: 5.625rem;
}

.accessory-card__text {
  grid-column: 2 / 3;
  align-self: center;
  margin-left: 1.25rem;
}

.accessory-card__coll {
  width: 100%;
}

/* Card spacing in desktop resolutions, set here to make variables behave correctly. */
.accessory-card__coll > div:nth-child(odd) {
  margin-right: var(--col-gutter);
}

/* Set margin-bottom on all but last two cards. */
.accessory-card__coll > div:nth-last-child(n+3) {
  margin-bottom: var(--col-padding);
}

.accessory-card__coll > *:not(:first-of-type) {
  margin-top: 0;
}

@media only screen and (max-width: 1023px) {

  /* Card spacing mobile and tablet override */
  .accessory-card__coll > *:not(:first-of-type) {
    margin-top: var(--col-padding);
  }

  .accessory-card__coll > div:nth-child(odd) {
    margin-right: 0;
  }

  .accessory-card__coll > div:nth-last-child(n+3) {
    margin-bottom: 0;
  }

  .accessory-card {
    max-width: 100%;
    margin-right: 0;
  }

  .accessory-card__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .accessory-card {
    padding: 1.875rem;
  }

  .accessory-card__media {
    max-height: 8.125rem; /* 130px */
    max-width: 8.125rem;
  }

  .accessory-card__text {
    margin-left: 1.875rem;
  }

  .accessory-card__coll {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .accessory-card {
    padding: 1.5rem;
  }

  .accessory-card__media {
    max-height: 6.875rem; /* 110px */
    max-width: 6.875rem;
  }

  .accessory-card__text {
    margin-left: 1.5rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1359px) {
  .accessory-card {
    width: 466px;
  }
}

@media only screen and (min-width: 1360px) {
  .accessory-card {
    width: 622px;
  }
}
