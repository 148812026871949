.link-cta {
  display: inline-block;
  color: var(--color-text-link);
  font-size: 0.875rem; /* 14px; */
  text-transform: uppercase;
  text-decoration: none;
  padding-bottom: 0.3125rem; /* 5px; */
  font-weight: 700;
  position: relative;
  line-height: 1.1875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;

  &:hover {
    color: var(--color-text-link-hover);

    &::after {
      animation: link-cta__hover-animation .7s cubic-bezier(0.58, 0.3, 0.005, 1);
    }
  }

  &:focus {
    color: var(--color-text-link-focus);
    outline: 1px dotted #444;
  }

  &::after {
    display: block;
    border-top: 3px solid var(--primary-color-high-contrast);
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes link-cta__hover-animation {
  0% {
    left: 0;
    right: 0;
  }

  45% {
    left: 100%;
    right: 0;
  }

  55% {
    left: 0;
    right: 100%;
  }

  100% {
    left: 0;
    right: 0;
  }
}
