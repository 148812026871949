.button-action {
  padding: 0.72rem 1.09rem;
  display: inline-block;
  border: 2px solid var(--primary-action-color);
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--font-size-button);
  line-height: var(--line-height-button);
  letter-spacing: 1px;
  color: var(--color-text-01);
  background-color: var(--primary-action-color);
  min-width: 8.125rem;

  &:visited,
  &:focus {
    color: var(--color-text-01);
  }
}

.button-action--secondary {
  background-color: transparent;
  border-color: var(--primary-action-color);

  &:visited,
  &:focus {
    color: var(--color-text-01);
    border-color: var(--primary-action-color);
  }
}

.button-action--inverted {
  background-color: #fff;
  border-color: #fff;
}

.button-action--disabled {
  pointer-events: none;
  opacity: .5;
}

.button-action--secondary-inverted {
  border-color: #fff;

  &:visited,
  &:focus {
    border-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .button-action {
    width: 100%;
  }
}
