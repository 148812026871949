/* Style imports according to ITCSS */

/* Settings layer */
@import 'settings/variables.css';
@import 'settings/fonts.css';

/* Generic layer */
@import 'generic/generic.css';

/* Elements layer */
@import 'elements/elements.css';

/* Components layer */
@import 'components/layout/grid/grid.css';
@import 'components/01-core/typography/list/list.css';
@import 'components/01-core/typography/hyperlink/hyperlink.css';
@import 'components/01-core/typography/tagline/tagline.css';
@import 'components/accordion/show-more/show-more.css';
@import 'components/action/action-button/action-button.css';
@import 'components/action/call-to-action-button/call-to-action-button.css';
@import 'components/action/link/link.css';
@import 'components/cards/accessory_card/accessory_card.css';
@import 'components/cards/analytics_card/analytics_card.css';
@import 'components/cards/how_to_buy_card/how_to_buy_card.css';
@import 'components/cards/related_link_card/related_link_card.css';
@import 'components/cards/support_card/support_card.css';
@import 'components/cards/technology_card/technology_card.css';
@import 'components/form_elements/checkbox/checkbox.css';
@import 'components/form_elements/search_bar/search_bar.css';
@import 'components/form_elements/select/select.css';
@import 'components/image/carousel/carousel.css';
@import 'components/loader/loader.css';
@import 'components/message/message.css';
@import 'components/modal/modal.css';

@import 'components/navigation/breadcrumb/breadcrumb.css';
@import 'components/navigation/sticky-menu/sticky-menu.css';
@import 'components/navigation/tabs/tabs.css';
@import 'components/table/table.css';

/* Utilities layer */
@import 'utilities/utilities.css';
