%title-attention {
  font-family: 'Lubalin Graph ECG', 'Open Sans', Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.5rem;
}

%title-section {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 2.1875rem;
  font-weight: 600;
  letter-spacing: -0.014375rem;
  line-height: 3.25rem;
}

%title-large {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  letter-spacing: -0.015625rem;
  line-height: 2.75rem;
}

%title-small {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: -0.016875rem;
  line-height: 1.75rem;
}

%title-poi {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

%text-display {
  font-size: 1.375rem;
  line-height: 2.25rem;
  font-weight: 300;
}

%text-body-default {
  font-size: 1rem;
  line-height: 1.625rem;
}

%text-body-s {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

%text-body-xs {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@media only screen and (min-width: 1360px) {
  %title-small {
    font-size: 1.375rem;
    letter-spacing: -0.01875rem;
    line-height: 2rem;
  }

  %title-large {
    font-size: 2.25rem;
    letter-spacing: -0.01875rem;
    line-height: 3.125rem;
  }

  %title-section {
    font-size: 2.8125rem;
    letter-spacing: -0.01875rem;
    line-height: 4rem;
  }

  %title-attention {
    font-size: 3.125rem;
    line-height: 4.0625rem;
  }

  %text-body-xs {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  %text-body-s {
    font-size: 1rem;
    line-height: 1.625rem;
  }

  %text-body-default {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  %text-display {
    font-size: 1.75rem;
    line-height: 3.0625rem;
  }
}

h1.title-attention,
h2.title-attention {
  @extend %title-attention;
}

h1 {
  @extend %title-section;
}

h2 {
  @extend %title-large;
}

h3 {
  @extend %title-small;
}

h4 {
  @extend %title-poi;
}

h5 {
  @extend %title-poi;
}

body {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 400;
  letter-spacing: var(--letter-spacing-default);

  @extend %text-body-default;
}

p,
h1,
h2,
h3,
h4,
h5 {
  padding-bottom: var(--default-padding);
}

pre {
  white-space: pre-wrap;
}
