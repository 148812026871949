.htb-card {
  display: grid;
  grid-template-rows: auto 1fr auto;
  box-shadow: var(--card-shadow);
  background-color: var(--color-ui-01);
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  max-width: 26rem;
}

.htb-card__media {
  grid-column: 1;
  grid-row: 1;
  align-items: center;
  margin: 3rem auto 2.5rem;
  display: flex;
  justify-content: center;
}

.htb-card__body {
  padding: 0 var(--col-gutter) 3rem;
  grid-column: 1;
  grid-row: 2;
}

.htb-card__text {
  padding-bottom: 0;
}

.htb-card__link,
.htb-card__link:visited {
  display: block;
  grid-column: 1;
  grid-row: 3;
  padding: 1.125rem 0;
  background-color: var(--primary-action-color);
  text-transform: uppercase;
  text-align: center;
  border-bottom: 0;
  text-decoration: none;
  font-weight: 700;
  font-size: var(--font-size-button);
  line-height: var(--line-height-button);
  letter-spacing: var(--letter-spacing-button);
  color: var(--color-ui-02);
}

.htb-card__link:hover {
  background-color: var(--primary-action-color-dark);
}

.htb-card__coll {
  width: 100%;
}

.htb-card__list {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  flex-wrap: wrap;
}

.htb-card__list li {
  display: flex;
  width: 100%;
  max-width: 26rem;
  list-style-type: none;
  margin-bottom: var(--col-gutter);

  /* Set desktop resolutions margin-right (gutter) */
  margin-right: var(--col-gutter);
}

@media only screen and (max-width: 1023px) {
  .htb-card__title,
  .htb-card__text {
    text-align: center;
  }

  /* Reset gutter for mobile/tablet resolutions */
  .htb-card__list li {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .htb-card__list {
    flex-wrap: nowrap;
  }

  .htb-card__list li:last-child {
    margin-right: 0;
  }
}
