.breadcrumb__list {
  list-style-type: none;
  display: flex;
  font-size: var(--font-size-x-small);
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumb__list-link {
  text-transform: uppercase;
  margin-right: 1.5em;
  font-weight: 400;
  border-bottom: 0;
  text-decoration: none;
  color: var(--color-text-link);

  &:visited {
    color: var(--color-text-link);
  }
}

.breadcrumb__list-item {
  &::after {
    content: '/';
    margin-right: 1.5em;
  }

  &:last-child {
    & .breadcrumb__list-link {
      font-weight: 700;
      margin-right: 0;
    }

    &::after {
      content: '';
      margin-right: 0;
    }
  }
}
